// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
  color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

//======================== || CK EDITOR || ============================================//
.ck-editor__editable {
  min-height: 300px;
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}
.default-entity-input {
  margin-right: auto;
  width: auto;
  min-width: 250px;
  margin-left: 9px;
  .MuiInputBase-root {
    margin-right: 0;
    &:before {
      border-width: 2px;
      border-color: #616161;
      border-bottom-style: solid;
    }
    input {
      font-weight: 600;
      font-size: 24px;
      &:hover {
        cursor: pointer;
      }
    }
    &.Mui-disabled {
      &:before {
        border-color: #bdbdbd;
      }
    }
  }
}
#root > div {
  transform: unset !important;
}
.mui-new-input-container {
  .MuiFormControl-root {
    margin-bottom: 0;
    .MuiFormLabel-root {
      &.Mui-focused {
        color: $darkGrey;
      }
    }
    &:hover {
      .MuiFormLabel-root {
        color: $darkGrey;
      }
    }
    .MuiInputBase-root {
      &.MuiOutlinedInput-root {
        border-radius: 0;
        textarea {
          height: 77px !important;
          font-size: 16px;
          color: $darkGrey;
        }
      }
      input {
        font-size: 16px;
        color: $darkGrey;
      }
    }
  }
}
.alert-groups-table {
  .MuiTableHead-root {
    .MuiTableRow-root {
      .MuiTableCell-root {
        padding: 10px 10px 1px 0px !important;
      }
    }
  }
  .MuiTableBody-root {
    .MuiTableRow-root {
      .MuiTableCell-root {
        padding: 5px 10px 5px 0px !important;
      }
    }
  }
}
#alert-page .MuiTabs-root {
  margin-bottom: 0 !important;
}
.MuiLinearProgress-root.MuiLinearProgress-colorSecondary.alert-progressbar {
  background: none !important;
  height: 14px;
  border: 1px solid #333333;
  border-radius: 10px;
  .MuiLinearProgress-bar.MuiLinearProgress-barColorSecondary {
    background-color: #333;
    border-radius: 10px;
  }
}
.alert-message-box {
  position: relative;
  padding: 0;
  textarea[aria-invalid='false'] {
    max-height: 70px !important;
    padding-right: 50px;
  }
  button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
.alert-message {
  textarea[aria-invalid='false'] {
    resize: vertical;
  }
}

#alert-page {
  .main-content {
    & > .scrollbar-container {
      overflow: hidden !important;
    }
  }
}

.follow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.follow-me-active-heading {
  color: green;
  font-size: 20px;
}

.follow-me-inactive-heading {
  color: red;
  font-size: 24px;
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  height: '100vh';
}

.follow-me-map-container {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 10px;
}

.follow-me-map-container > div {
  height: 85vh;
  width: 100%;
}
